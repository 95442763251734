<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center rounded-0"
          color="white"
          white="60"
          min-width="60"
          height="60"
          contain
        >
          <v-img src="/logo.png" class="rounded-0" />
        </v-list-item-avatar>
        <div class="ml-auto">
          <v-icon
            color="black"
            large
            v-if="value"
            @click="setDrawer(!$store.state.drawer)"
          >
            mdi-menu
          </v-icon>
          <v-icon
            color="black"
            large
            v-else
            @click="setDrawer(!$store.state.drawer)"
            >mdi-menu</v-icon
          >
        </div>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item"> </base-item>

        <v-divider class="mb-2"> </v-divider>
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    itemsAdmin: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/app/dashboard",
      },
      {
        icon: "mdi-account-multiple",
        title: "Users",
        to: "/app/pages/users",
      },
      {
        title: "SMS Messages",
        icon: "mdi-email-outline",
        to: "/app/pages/Sms",
      },
      {
        title: "SMS Schedules",
        icon: "mdi-calendar-month-outline",
        to: "/app/pages/Schedule",
      },
      {
        title: "Settings",
        icon: "mdi-cog-outline",
        to: "/app/pages/Settings",
      },
      {
        title: "Log",
        icon: "mdi-alert-circle",
        to: "/app/pages/logs",
      },
    ],
    itemsUser: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/app/dashboard",
      },
    ],
  }),
  mounted() {
    const loginFlag = localStorage.getItem("user");
    if (!loginFlag) this.$router.push("/");
  },

  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),

    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      if (this.getUserDetails.user_type === "admin") {
        return this.itemsAdmin.map(this.mapItem);
      } else {
        return this.itemsUser.map(this.mapItem);
      }
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
